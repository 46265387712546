import { ReactiveComponent } from '@/commons/component/ReactiveComponent'
import { Component, Watch } from '@/commons/component/decorators'

import { Remote } from '@/services/Remote'

import { Breadcrumb } from '@/widgets/Breadcrumb'
import { PieChart } from './PieChart'

import { Container, Main, Card, Row, Col, Select, Option, RadioGroup, RadioButton, Button, DatePicker, Table, TableColumn } from 'element-ui'

@Component()
export
class SupervisorRepairDataView extends ReactiveComponent {
  faultData = []
  refundTypeData = []
  refundSourceData = []
  payTypeData = []

  queryAgent = ''
  queryChannel = ''
  queryDistrict = ''
  queryRegion = ''
  querySupervisor = ''
  querySite = ''
  queryPartner = ''

  queryTimeRangeType = ''
  queryTimeRange = []
  queryStartTime = ''
  queryEndTime = ''

  agentOptions = []
  channelOptions = []
  districtOptions = []
  regionOptions = []
  supervisorOptions = []
  siteOptions = []
  partnerOptions = []

  created() {
    this.loadAgentOptions()
    this.loadDistrictOptions()
    this.loadSupervisorOptions()
    // this.loadPartnerOptions()

    this.loadData()
  }

  @Watch('queryAgent')
  @Watch('queryChannel')
  @Watch('queryDistrict')
  @Watch('queryRegion')
  @Watch('querySupervisor')
  @Watch('querySite')
  @Watch('queryPartner')
  @Watch('queryTimeRangeType')
  @Watch('queryTimeRange.0')
  @Watch('queryTimeRange.1')
  async loadData() {
    const { faultData, refundTypeData, refundSourceData, payTypeData } = await Remote.get().http('/admin/api/operation/index_operation_data', {
      params: {
        agent_id: this.queryAgent,
        supervisor_id: this.querySupervisor,
        district: this.queryDistrict,
        classify: this.querySite,
        province: this.queryRegion,
        channel: this.queryChannel,

        time_type: this.queryTimeRangeType,

        b_time: this.queryTimeRange?.[0]?.toLocaleString?.('sv-SE'),
        e_time: this.queryTimeRange?.[1]?.toLocaleString?.('sv-SE'),
      }
    })

    this.faultData = faultData
    this.refundTypeData = refundTypeData
    this.refundSourceData = refundSourceData
    this.payTypeData = payTypeData
  }

  async loadAgentOptions() {
    const data = await Remote.get().http('/admin/api/operation/get_agent')

    this.agentOptions = data
  }

  async loadDistrictOptions() {
    const { district, classify, channel, area } = await Remote.get().http('/admin/api/operation/get_district')

    this.districtOptions = district
    this.channelOptions = channel
    this.siteOptions = classify
    this.regionOptions = area
  }

  @Watch('queryAgent')
  @Watch('queryDistrict')
  async loadSupervisorOptions() {
    const data = await Remote.get().http('/admin/api/operation/get_supevisor', { params: { agent_id: this.queryAgent, district: this.queryDistrict }})

    this.supervisorOptions = data
  }

  async loadPartnerOptions() {
    const data = await Remote.get().http('/admin/api/operation/get_partner')

    this.partnerOptions = data
  }

  async exportData(exportType) {
    const url = await Remote.get().http('/admin/api/operation/index_operation_data', {
      params: {
        agent_id: this.queryAgent,
        supervisor_id: this.querySupervisor,
        district: this.queryDistrict,
        classify: this.querySite,
        province: this.queryRegion,
        channel: this.queryChannel,

        time_type: this.queryTimeRangeType,

        b_time: this.queryTimeRange?.[0]?.toLocaleString?.('sv-SE'),
        e_time: this.queryTimeRange?.[1]?.toLocaleString?.('sv-SE'),

        is_export: exportType,
      }
    })

    Remote.get().download(url)
  }

  render() {
    return (
      <Container class="supervisor-repair-data-main base-main">
        <Breadcrumb />

        <Main>
          <Card>
            <Row gutter={ 24 }>
              <Col span={ 2 }>
                <label for="search-agent">代理商名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="代理商名称" placeholder="请输入" id="search-agent" value={ this.queryAgent } onInput={ e => (this.queryAgent = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.agentOptions.map(agent => (
                      <Option value={ agent.id } label={ agent.name } key={ agent.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-channel">渠道</label>
              </Col>
              <Col span={ 4 }>
                <Select label="渠道" placeholder="请输入" id="search-channel" value={ this.queryChannel } onInput={ e => (this.queryChannel = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.channelOptions.map(channel => (
                      <Option value={ channel.id } label={ channel.name } key={ channel.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-district">片区</label>
              </Col>
              <Col span={ 4 }>
                <Select label="片区" placeholder="请输入" id="search-district" value={ this.queryDistrict } onInput={ e => (this.queryDistrict = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.districtOptions.map(district => (
                      <Option value={ district.id } label={ district.name } key={ district.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-region">地域</label>
              </Col>
              <Col span={ 4 }>
                <Select label="地域" placeholder="请输入" id="search-region" value={ this.queryRegion } onInput={ e => (this.queryRegion = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.regionOptions.map(region => (
                      <Option value={ region.id } label={ region.name } key={ region.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-supervisor">经销点名称</label>
              </Col>
              <Col span={ 4 }>
                <Select label="经销点名称" placeholder="请输入" id="search-supervisor" value={ this.querySupervisor } onInput={ e => (this.querySupervisor = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.supervisorOptions.map(supervisor => (
                      <Option value={ supervisor.id } label={ supervisor.name } key={ supervisor.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-site">投放场景</label>
              </Col>
              <Col span={ 4 }>
                <Select label="投放场景" placeholder="请输入" id="search-site" value={ this.querySite } onInput={ e => (this.querySite = e) } suffix-icon="el-icon-search" clearable>
                  {
                    this.siteOptions.map(site => (
                      <Option value={ site.id } label={ site.name } key={ site.id } />
                    ))
                  }
                </Select>
              </Col>

              <Col span={ 2 }>
                <label for="search-timerange">时间段</label>
              </Col>
              <Col span={ 10 }>
                <DatePicker type="daterange" value={ this.queryTimeRange } onInput={ e => (this.queryTimeRange  = e) } range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-right: 1em" />
                <RadioGroup value={ this.queryTimeRangeType } onInput={ e => (this.queryTimeRangeType = + e) } size="small">
                  <RadioButton label={ 1 }>今日</RadioButton>
                  <RadioButton label={ 2 }>7日</RadioButton>
                  <RadioButton label={ 3 }>30日</RadioButton>
                  <RadioButton label={ 4 }>90日</RadioButton>
                </RadioGroup>
              </Col>
            </Row>
          </Card>

          <Card>
            <Row slot="header">
              <Col span={ 2 }>
                <span class="label">故障情况</span>
              </Col>
              <Col span={ 2 } offset={ 20 }>
                <Button type="primary" onClick={ this.exportData.bind(this, 1) }>导出数据</Button>
              </Col>
            </Row>

            <Row gutter={ 12 }>
              <Col span={ 12 }>
                <Table data={ this.faultData } stripe>
                  <TableColumn label="故障类型" prop="name" />
                  <TableColumn label="故障次数" prop="total" />
                  <TableColumn label="故障占比 / %" scopedSlots={{
                    default: scope => (scope.row.rate ?? 0) * 100 | 0
                  }} />
                </Table>
              </Col>

              <Col span={ 12 }>
                <div class="chart">
                  <PieChart data={ this.faultData } title="故障情况" height={ 400 } />
                </div>
              </Col>
            </Row>
          </Card>

          <Card>
            <Row slot="header">
              <Col span={ 2 }>
                <span class="label">退款原因分析</span>
              </Col>
              <Col span={ 2 } offset={ 20 }>
                <Button type="primary" onClick={ this.exportData.bind(this, 2) }>导出数据</Button>
              </Col>
            </Row>

            <Row gutter={ 12 }>
              <Col span={ 12 }>
                <Table data={ this.refundTypeData } stripe>
                  <TableColumn label="退款原因" prop="name" />
                  <TableColumn label="退款次数" prop="total" />
                  <TableColumn label="退款占比 / %" scopedSlots={{
                    default: scope => (scope.row.rate ?? 0) * 100
                  }} />
                  <TableColumn label="退款金额 / 元" prop="refund_amount" />
                </Table>
              </Col>

              <Col span={ 12 }>
                <div class="chart">
                  <PieChart data={ this.refundTypeData } title="总退款" height={ 400 } />
                </div>
              </Col>
            </Row>
          </Card>

          <Card>
            <Row slot="header">
              <Col span={ 2 }>
                <span class="label">退款来源分析</span>
              </Col>
              <Col span={ 2 } offset={ 20 }>
                <Button type="primary" onClick={ this.exportData.bind(this, 3) }>导出数据</Button>
              </Col>
            </Row>

            <Row gutter={ 12 }>
              <Col span={ 12 }>
                <Table data={ this.refundSourceData } stripe>
                  <TableColumn label="退款来源" prop="name" />
                  <TableColumn label="退款次数" prop="total" />
                  <TableColumn label="退款占比 / %" scopedSlots={{
                    default: scope => (scope.row.rate ?? 0) * 100
                  }} />
                </Table>
              </Col>

              <Col span={ 12 }>
                <div class="chart">
                  <PieChart data={ this.refundSourceData } title="退款总计" height={ 400 } />
                </div>
              </Col>
            </Row>
          </Card>

          <Card>
            <Row slot="header">
              <Col span={ 2 }>
                <span class="label">支付渠道分析</span>
              </Col>
              <Col span={ 2 } offset={ 20 }>
                <Button type="primary" onClick={ this.exportData.bind(this, 5) }>导出数据</Button>
              </Col>
            </Row>

            <Table data={ this.payTypeData } stripe>
              <TableColumn label="渠道" prop="name" />
              <TableColumn label="支付金额 / 元" prop="pay_amount" />
              <TableColumn label="支付订单数" prop="total" />
              <TableColumn label="退款金额 / 元" prop="refund_amount" />
              <TableColumn label="退款订单数" prop="refund_total" />
              <TableColumn label="退款率 / %" scopedSlots={{
                default: scope => (scope.row.rate ?? 0) * 100
              }} />
            </Table>
          </Card>

        </Main>
      </Container>
    )
  }
}
